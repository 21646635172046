'use strict';

import BaseUseClass from 'Commons/js/baseUseClass';
import config from './loader-config.json';

const VIEWPORT_THRESHOLD = 500;
const INIT_DATA_ATTRIBUTE = 'data-initialized';
const TARGET_DATA_ATTRIBUTE = 'data-component-path';
const SHOULD_LOAD_IMMEDIATELY_DATA_ATTRIBUTE = 'data-immediate';
const LOADER_DATA_ATTRIBUTE = 'data-loader';
const LOADER_WEBPACK = 'webpack';

const getDataAttrValue = (attr, entry) => {
    const item = entry.attributes.getNamedItem(attr);
    return item && item.value;
};
const getConfigEntry = (conf, name) => conf.filter((entry) => entry.name === name)[0];
const baseUseClass = new BaseUseClass();

const observerOptions = {
    root: null,
    rootMargin: `0px 0px ${VIEWPORT_THRESHOLD}px 0px`,
    threshold: 0,
};

let intersectionObserver;

const initializeComponent = (element) => {
    const componentName = getDataAttrValue('data-component-path', element);
    const componentEntry = getConfigEntry(config, componentName);
    if (!componentEntry) {
        throw new Error(`Cannot find component path for: ${componentName}`);
    } else {
        const initAttribute = document.createAttribute(INIT_DATA_ATTRIBUTE);
        initAttribute.value = 'true';
        element.attributes.setNamedItem(initAttribute);
        import(
            /* webpackInclude: /\.lazy.js$/ */
            /* webpackExclude: /__tests__|utils[.]jest|clientlibs-author|editor/ */
            /* webpackMode: "lazy" */
            /* webpackChunkName: "chunk-[index]" */
            `Components/${componentEntry.src}`
            ).then((Module) => {
            // eslint-disable-next-line no-shadow
            const init = (Module, element) => {
                console.log('Lazy Loading: ', componentName);
                const componentModule = new Module.default(element, baseUseClass);
            };
            measure(
                element.dataset['componentPath'],
                element.dataset['trackingRegionid'],
                init.bind(this, Module, element),
            );
        })
            .catch((err) => {
                console.error(err);
            });
    }
};

/**
 * To measure the initiation times and provide it in the audits.
 * @param componentPath - the name of the component. To connect a measurement to the component.
 * @param regionId - a unique id of a component on a page. Also to create a measurement name.
 * @param fn - the function that should be measured.
 */
function measure(componentPath, regionId, fn) {
    const start = `${regionId}:start`;
    const done = `${regionId}:done`;
    performance.mark(start);
    fn();
    performance.mark(done);
    performance.measure(`${regionId}__${componentPath}`, start, done);
}

function observeElement(element) {
    !element.getAttribute(INIT_DATA_ATTRIBUTE) && intersectionObserver.observe(element);
}

function lazyLoadModules(listOfElements) {
    const lazyLoaders = listOfElements.filter((entry) => !getDataAttrValue(SHOULD_LOAD_IMMEDIATELY_DATA_ATTRIBUTE, entry));
    lazyLoaders.forEach(observeElement);
}

function directLoadModules(listOfElements) {
    const immediateLoaders = listOfElements
        .filter((entry) => getDataAttrValue(SHOULD_LOAD_IMMEDIATELY_DATA_ATTRIBUTE, entry))
        .filter((entry) => !entry.getAttribute(INIT_DATA_ATTRIBUTE));
    immediateLoaders.forEach(initializeComponent);
}

function initAllComponents(rootElement) {
    const targets = Array.from(rootElement.querySelectorAll(`[${TARGET_DATA_ATTRIBUTE}][${LOADER_DATA_ATTRIBUTE}="${LOADER_WEBPACK}"]`));
    directLoadModules(targets);
    lazyLoadModules(targets);
}

function init() {
    // needed for istanbul ignore to work
    intersectionObserver = new IntersectionObserver((entries, observer) => {
        entries
            .filter((entry) => entry.isIntersecting)
            .filter((entry) => !getDataAttrValue(INIT_DATA_ATTRIBUTE, entry.target))
            .forEach((entry) => {
                const element = entry.target;
                observer.unobserve(element);
                initializeComponent(element);
            });
    }, observerOptions);

    let mutationDebounce;
    const mutationObserver = new MutationObserver((mutations) => {
        if (mutations
            .filter((mutation) => mutation.target.tagName !== 'HTML')
            .filter((mutation) => mutation.addedNodes.length)) {
            clearTimeout(mutationDebounce);
            mutationDebounce = setTimeout(() => {
                initAllComponents(document);
            });
        }
    });
    mutationObserver.observe(document, {childList: true, subtree: true});

    initAllComponents(document);
}
document.addEventListener('immediateJSLoadFinished', init);

export default {
    init,
    initAllComponents,
    initElement: observeElement,
};
